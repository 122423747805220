import { LabelValueItem, StatisticBadge } from '@/components/common';
import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useTimesheetPayrollEntryQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollEntityQuery';
import { minutesToTime } from '@/lib/utils';
import { TimesheetPayrollTherapist } from '@/views/timesheet-details/interfaces/timesheet-payroll-therapist.interface';
import Calendar from './../../../../public/icons/calendar-blue.svg';
import Clock from './../../../../public/icons/clock.svg';
import Wallet from './../../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';


export type PreviewRestBrakeDialogRef = {
  openById: (id: string, isShowAnalytics?: boolean, period?: string) => void;
};

const PreviewRestBrakeDialog = forwardRef<PreviewRestBrakeDialogRef>(({}, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [entryId, setEntryId] = useState<string>();
  const [period, setPeriod] = useState<string>();
  const [isShowAnalytics, setIsShowAnalytics] = useState<boolean>(false);

  const t = useTranslations();
  const [details, setDetails] = useState<TimesheetPayrollTherapist>();
  const { data: entry } = useTimesheetPayrollEntryQuery({ id: entryId });

  useImperativeHandle(ref, () => ({
    openById: (id, showAnalytics: boolean = false, period) => {
      setIsShowAnalytics(showAnalytics);
      setEntryId(id);
      setPeriod(period);
      setIsOpen(true);
    },
  }));

  useEffect(() => {
    if (entry) {
      setDetails(entry);
    }
  }, [entry]);

  useEffect(() => {
    if (!isOpen && entryId) {
      setEntryId(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full min-w-[700px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.Timesheets.restBreakDetails')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        {isShowAnalytics && (
          <div className="mb-6 grid grid-cols-2 gap-4">
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Calendar}
              label={t('Pages.Timesheets.period')}
              value={period ?? ''}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Clock}
              label={t('Pages.Timesheets.duration')}
              value={details && details.duration ? minutesToTime(details.duration) : '---'}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Wallet}
              label={t('Pages.Timesheets.totalPay')}
              value={details && details.totalPay ? `$${details.totalPay.toFixed(2)}` : '---'}
            />
          </div>
        )}

        <div className="grid grid-cols-form-cols-2 gap-8">
          <LabelValueItem
            label={t('Forms.duration.label')}
            value={isShowAnalytics ? '10 mins' : details && details.duration ? minutesToTime(details.duration) : '---'}
          />
          <LabelValueItem
            label={t('Forms.payRate.label')}
            value={isShowAnalytics ? '$16.50/h' : details && details.payRate ? `$${details.payRate}` : '---'}
          />
          <LabelValueItem
            label={t('Forms.totalPay.label')}
            value={isShowAnalytics ? '$2.75' : details && details.totalPay ? `$${details.totalPay.toFixed(2)}` : '---'}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
});

PreviewRestBrakeDialog.displayName = 'PreviewRestBrakeDialog';

export { PreviewRestBrakeDialog };
