import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TimesheetPayrollTherapist } from '@/views/timesheet-details/interfaces/timesheet-payroll-therapist.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

interface Params {
  id?: string;
}



export type Options = Omit<UseQueryOptions<unknown, DefaultError, TimesheetPayrollTherapist | null>, 'queryKey' | 'queryFn'>;

export const useTimesheetPayrollEntryQuery = (params: Params, options?: Options) => {
  const _query = async (): Promise<TimesheetPayrollTherapist | null> => {
    if (params.id) {
      const { data } = await axiosInterceptorInstance.get(`timesheets/payroll-entries/${params.id}`);
      return data;
    } 
    return null;
  };

  return useQuery({
    queryKey: ['timesheets', 'payroll-entries', params.id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
