import { ALPHABETIC_NUMBERS, DATE_MMDDYYYY } from '@/common/constants';
import { validatePhone } from '@/components/form';
import { z } from 'zod';

export const createClientAndGuardianValidationSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'Forms.firstName.required' })
      .min(2, { message: 'Forms.firstName.min' })
      .max(50, { message: 'Forms.firstName.max' }),
    // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
    caseManager: z.union([
      z.literal(''),
      z.string().min(2, { message: 'Forms.caseManager.min' }).max(50, { message: 'Forms.caseManager.max' }),
      // .regex(USER_NAME, { message: 'Forms.caseManager.onlyAlphabetic' }),
    ]),
    lastName: z
      .string()
      .min(1, { message: 'Forms.lastName.required' })
      .min(2, { message: 'Forms.lastName.min' })
      .max(50, { message: 'Forms.lastName.max' }),
    // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
    uci: z
      .string({ message: 'Forms.uciNumber.required' })
      .min(1, { message: 'Forms.uciNumber.required' })
      .regex(ALPHABETIC_NUMBERS, { message: 'Forms.uciNumber.invalid' }),
    dob: z
      .string()
      .min(1, { message: 'Forms.dateOfBirth.required' })
      .regex(DATE_MMDDYYYY, { message: 'Forms.dateOfBirth.invalid' }), // MM.DD.YYYY
    // address: z.union([
    //   z.string().length(0),
    //   z.string().min(5, { message: 'Forms.address.min' }).max(200, { message: 'Forms.address.max' }),
    // ]),
    addresses: z.array(
      z.object({
        lng: z.number().optional(),
        lat: z.number().optional(),
        address: z.string(),
      })
    ),
    regionalCenter: z
      .string({ message: 'Forms.regionalCenter.required' })
      .min(1, { message: 'Forms.regionalCenter.required' }),
    // GUARDIAN
    firstNameGuardian: z
      .string()
      .min(1, { message: 'Forms.firstName.required' })
      .min(2, { message: 'Forms.firstName.min' })
      .max(50, { message: 'Forms.firstName.max' }),
    // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
    lastNameGuardian: z
      .string()
      .min(1, { message: 'Forms.lastName.required' })
      .min(2, { message: 'Forms.lastName.min' })
      .max(50, { message: 'Forms.lastName.max' }),
    // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
    email: z.union([z.literal(''), z.string().max(100, { message: 'Forms.email.max' }).email('Forms.invalid')]),
    primaryNumber: z.string().min(1, { message: 'Forms.primaryNumber.required' }),
    secondaryNumber: z.string().optional(),
    primaryRelationship: z.string().optional(),
    secondaryRelationship: z.string().optional(),
    languages: z
      .array(
        z.object({
          label: z.string(),
          value: z.any(),
        })
      )
      .min(1, { message: 'Forms.languages.required' }),
  })
  .superRefine((data, ctx) => {
    if (data.primaryNumber && data.primaryNumber.length > 0) {
      if (!validatePhone(data.primaryNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['primaryNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }
    if (data.secondaryNumber && data.secondaryNumber.length > 0) {
      if (!validatePhone(data.secondaryNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['secondaryNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }

    data.addresses.forEach((value, i) => {
      if (!value.lng || !value.lat) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`addresses.${i}`],
          message: 'Forms.address.pleaseChooseViaSelector',
        });
      }
      if (value.address.trim().length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`addresses.${i}`],
          message: 'Forms.address.required',
        });
      }
      if (value.address.length < 5) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`addresses.${i}`],
          message: 'Forms.address.min',
        });
      }
      if (value.address.length > 200) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`addresses.${i}`],
          message: 'Forms.address.max',
        });
      }
    });
  });

export type CreateClientAndGuardianValidationSchema = z.infer<typeof createClientAndGuardianValidationSchema>;
