import { Chips, LabelValueItem, StatisticBadge } from '@/components/common';
import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useTimesheetPayrollEntryQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollEntityQuery';
import { getColorForServiceRole, minutesToTime } from '@/lib/utils';
import { TimesheetPayrollTherapist } from '@/views/timesheet-details/interfaces/timesheet-payroll-therapist.interface';
import Calendar from './../../../../public/icons/calendar-blue.svg';
import Folder from './../../../../public/icons/folder-blue.svg';
import Wallet from './../../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import dayjs from 'dayjs';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

export type PreviewShiftTimesheetDialogRef = {
  openById: (id: string, period: string) => void;
};

const PreviewShiftTimesheetDialog = forwardRef<PreviewShiftTimesheetDialogRef, { showPayMetrics?: boolean }>(
  ({ showPayMetrics = false }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [entryId, setEntryId] = useState<string>();
    const [period, setPeriod] = useState<string>();
    const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
    const [details, setDetails] = useState<TimesheetPayrollTherapist>();
    const t = useTranslations();
    const { checkPermissions } = useIsAllowed();

    const { data: entry, refetch } = useTimesheetPayrollEntryQuery({ id: entryId });

    useImperativeHandle(ref, () => ({
      openById: (id, period) => {
        setEntryId(id);
        setPeriod(period);
        setIsOpen(true);
      },
    }));

    useEffect(() => {
      if (entry) {
        setDetails(entry);
      }
    }, [entry]);

    useEffect(() => {
      if (!isOpen && entryId) {
        setEntryId(undefined);
      }
    }, [isOpen]);

    const previewService = (id: string) => {
      previewServiceDialogRef.current?.openById(id);
    };

    return (
      <>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full min-w-[600px]">
            <DialogHeader>
              <DialogTitle>{t('Pages.Timesheets.shiftDetails')}</DialogTitle>
              <DialogDescription></DialogDescription>
            </DialogHeader>

            <div className="mb-6 grid grid-cols-2 gap-4">
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Calendar}
                label={t('Pages.Timesheets.period')}
                value={period ?? ''}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Folder}
                label={t('Pages.Timesheets.hoursProvided')}
                value={details ? minutesToTime(details.duration) : `---`}
              />
              {checkPermissions([PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC]) || showPayMetrics ? (
                <StatisticBadge
                  size="lg"
                  iconHeight={54}
                  iconWidth={54}
                  classNameIcon="p-3 ml-3"
                  svgPath={Wallet}
                  label={t('Pages.Timesheets.totalPay')}
                  value={details && details.totalPay ? `$${details.totalPay.toFixed(2)}` : '---'}
                />
              ) : null}
            </div>

            <div className="grid grid-cols-form-cols-2 gap-8">
              <LabelValueItem label={t('Forms.typeOfVisit.label')} value={details?.workType ?? '---'} />
              <LabelValueItem
                label={t('Forms.date.label')}
                value={details?.date ? dayjs(details.date).format('MM.DD.YYYY') : '---'}
              />
              <LabelValueItem
                label={t('Forms.hoursWorked.label')}
                value={details?.duration ? minutesToTime(details.duration) : '---'}
              />
              {checkPermissions([PERMISSIONS_TYPES.VIEW_PAY_RATES_METRIC]) || showPayMetrics ? (
                <LabelValueItem
                  label={t('Forms.payRate.label')}
                  value={details?.payRate ? `$${details.payRate}` : '---'}
                />
              ) : null}
              {checkPermissions([PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC]) || showPayMetrics ? (
                <LabelValueItem
                  label={t('Forms.totalPay.label')}
                  value={details?.totalPay ? `$${details.totalPay.toFixed(2)}` : '---'}
                />
              ) : null}
              <LabelValueItem label={t('Forms.service.label')} withoutBorder>
                <Chips
                  warningBadge={details?.shift.service.deletedAt && t('Common.archived')}
                  color={getColorForServiceRole(
                    details?.shift.service.serviceRole?.title,
                    details?.shift.service.serviceRole?.speciality?.code
                  )}
                  title={details?.shift.service ? details.shift.service.title : '---'}
                  onClickAction={() => previewService(details?.shift.service?.id as string)}
                />
              </LabelValueItem>
            </div>
          </DialogContent>
        </Dialog>
        <PreviewServiceDialog onUpdated={refetch} ref={previewServiceDialogRef} />
      </>
    );
  }
);

PreviewShiftTimesheetDialog.displayName = 'PreviewShiftTimesheetDialog';

export { PreviewShiftTimesheetDialog };
