import { ColumnDef } from '@tanstack/react-table';
import { Timesheet } from '../interfaces/timesheet.interface';
import { Chips } from '@/components/common';
import dayjs from '@/lib/dayjsConfig';
import { TIMESHEET_STATUS_COLORS } from '@/common/constants';
import { capitalizeFirstLetter, minutesToTime } from '@/lib/utils';

type Params = {
  t: any;
};

export const getTimesheetsColumns = ({t}: Params): ColumnDef<Timesheet>[] => {
  const columns: ColumnDef<Timesheet>[] = [
    {
      id: 'Period',
      enablePinning: false,
      header: () => <span>{t('Tables.period')}</span>,
      cell: ({ row }) => {
        if (row.original.periodTo && row.original.periodFrom) {
          return <span>{`${dayjs(row.original.periodFrom).format('MMM')} ${dayjs(row.original.periodFrom).format('DD')} - ${dayjs(row.original.periodTo).format('MMM')} ${dayjs(row.original.periodTo).format('DD')}`}</span>;
        } 
      },
      size: 140,
    },
    // {
    //   id: 'Amount of Shifts',
    //   enablePinning: false,
    //   header: () => <span>{t('Tables.amountOfShifts')}</span>,
    //   cell: ({ row }) => {
    //     if (row.original.report) {
    //       return <span>{row.original.report.shiftsCount}</span>;
    //     }
    //   },
    //   size: 140,
    // },
    {
      id: 'Amount of Hours Worked',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfHoursWorked')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.totalDuration ? minutesToTime(row.original.report.totalDuration) : '---'}</span>;
        }
      },
      size: 140,
    },
    {
      id: 'Drive time',
      enablePinning: false,
      header: () => <span>{t('Tables.driveTime')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.totalDriveTime ? minutesToTime(row.original.report.totalDriveTime) : '---'}</span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of miles',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfMiles')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.totalDriveMileage ? row.original.report.totalDriveMileage.toFixed(2) : '---'}</span>;
        }
      },
      size: 140,
    },
    {
      id: 'Amount of rest breaks',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfRestBreaks')}</span>,
      cell: ({ row }) => {
        if (row.original.report) {
          return <span>{row.original.report.breaksCount ? row.original.report.breaksCount : '0'}</span>;
        }
      },
      size: 140,
    },
    {
      id: 'Status',
      enablePinning: false,
      header: () => <span>{t('Tables.status')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          return <Chips color={(TIMESHEET_STATUS_COLORS as any)[row.original.status]} title={capitalizeFirstLetter(row.original.status)} />;
        }
      },
      size: 140,
    },
  ];

  return columns;
};
