import { ColumnDef } from '@tanstack/react-table';
import { MyClient } from '../interfaces/my-clients.interface';
import { Chips, CollapsibleColumn } from '@/components/common';
import { Colors } from '@/common/enums';
import { compileFullName, getColorForServiceRole, transformApiDateToViewDate, transformDateToViewDate } from '@/lib/utils';
import { SERVICES_STATUS_COLORS, USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';

type Params = {
  t: any;
  onOpenClientProfile: (id: string) => void;
  onOpenGuardianProfile: (id: string) => void;
};

export const getMyClientsColumns = ({
  t,
  onOpenClientProfile,
  onOpenGuardianProfile,
}: Params): ColumnDef<MyClient>[] => {
  return [
    {
      id: 'Title',
      accessorKey: 'title',
      header: () => <span>{t('Tables.title')}</span>,
      size: 250, //starting column size
      enableSorting: false,
    },
    {
      id: 'Client',
      header: () => <span>{t('Tables.client')}</span>,
      cell: ({ row }) => {
        if (row.original.client) {
          return (
            <Chips
              onClickAction={(e) => {
                onOpenClientProfile(row.original.client.id);
                e.stopPropagation();
              }}
              color={Colors.indigo}
              title={compileFullName(row.original.client)}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enableSorting: false,
    },
    {
      id: 'Service Role',
      header: () => <span>{t('Tables.serviceRole')}</span>,
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          const sr = row.original.serviceRole;
          return <Chips color={getColorForServiceRole(sr.title, sr.speciality.code)} title={sr.title} />;
        }
        return <></>;
      },
      enableSorting: false,
      size: 250,
    },
    {
      id: 'Guardian',
      accessorKey: 'type',
      header: () => <span>{t('Tables.guardian')}</span>,
      cell: ({ row }) => {
        if (row.original.client && row.original.client.guardians) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {row.original.client.guardians.map((guardian) => (
                <Chips
                  onClickAction={(e) => {
                    onOpenGuardianProfile(guardian.id);
                    e.stopPropagation();
                  }}
                  key={guardian.id}
                  color={USER_ROLES_COLORS[ROLE_TYPES.Guardian]}
                  title={compileFullName(guardian)}
                />
              ))}
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      size: 250,
    },
    {
      id: 'Frequency',
      accessorFn: (row) =>  `${row.hours}h/${row.frequency}`,
      header: () => <span>{t('Tables.frequency')}</span>,
      enableSorting: false,
      size: 140,
    },
    {
      id: 'Stage',
      accessorKey: 'status',
      header: () => <span>{t('Tables.stage')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          const color = (SERVICES_STATUS_COLORS as any)[row.original.status];
          if(color) {
            return <Chips color={color} title={row.original.status} />;
          }
        }
        return <></>;
      },
      enableSorting: false,
      size: 200,
    },
    {
      id: 'Authorized To',
      accessorKey: 'authorizedTo',
      accessorFn: (row) => transformDateToViewDate(row.authorizedTo, false),
      header: () => <span>{t('Tables.authorizedTo')}</span>,
      enableSorting: false,
      size: 140,
    },
    {
      id: 'Authorized From',
      accessorKey: 'authorizedFrom',
      accessorFn: (row) => transformApiDateToViewDate(row.authorizedFrom),
      header: () => <span>{t('Tables.authorizedFrom')}</span>,
      enableSorting: false,
      size: 140,
    },
    {
      id: 'Languages',
      header: () => <span>{t('Tables.languages')}</span>,
      cell: ({ row }) => {
        if (row.original.languages) {
          return <span className="">{row.original.languages.join(', ')}</span>;
        } else {
          return <span></span>;
        }
      },
      enableSorting: false,
      size: 100,
    },
    {
      id: 'Address',
      header: () => <span>{t('Tables.address')}</span>,
      cell: ({ row }) => {
        if (row.original.client.addresses) {
          const visible = row.original.client.addresses.slice(0, 1);
          const hidden = row.original.client.addresses.slice(1);

          const parseFn = (item: any) => ({
            id: item.id,
            title: item.address,
            color: Colors.none,
          });

          return <CollapsibleColumn withoutChips visible={visible.map(parseFn)} hidden={hidden.map(parseFn)} />;
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
  ];
};
