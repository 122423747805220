'use client';
import Folder from './../../../public/icons/folder-2.svg';
import Calendar from './../../../public/icons/calendar.svg';
import CalendarPurple from './../../../public/icons/calendar-purple.svg';
import CalendarYellow from './../../../public/icons/calendar-yellow.svg';
import DocumentRose from './../../../public/icons/document-rose.svg';
import HospitalGreen from './../../../public/icons/hospital-green.svg';
import UserSquareBlue from './../../../public/icons/user-square-blue.svg';
import UserSquareCyan from './../../../public/icons/user-square-cyan.svg';
import UserSquareRose from './../../../public/icons/user-square-rose.svg';
import { useTranslations } from 'next-intl';
import { StatisticBadge } from '@/components/common';
import { NavItem } from './components/nav-item';
import { LogEventItem } from './components/log-event-item';
import { EventDashboardStatus } from './event-dashboard-status';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '../all-shifts/preview/preview-shift.dialog';
import { createRef } from 'react';
import { useDashboardsQuery } from '@/hooks/fetchers/queries/dashboards/useDashboardsQuery';
import { useSession } from 'next-auth/react';

export function DashboardAdminView() {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const { data: session } = useSession();

  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();

  const { data: dashboardData, refetch } = useDashboardsQuery();

  return (
    <main className="pl-4 pr-6">
      <h1 className="mt-2 min-h-16 py-4 text-2xl font-bold">
        {session?.user && t('Pages.Dashboard.title', { name: session.user.firstName })}
      </h1>
      <div className="mb-6 grid grid-cols-4 gap-4">
        <StatisticBadge
          className=""
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Folder}
          label={t('Pages.Dashboard.reportDue')}
          value={dashboardData?.reportDueDocumentsCount ?? 0}
        />
        <StatisticBadge
          className=""
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Folder}
          label={t('Pages.Dashboard.notesDue')}
          value={dashboardData?.notesDueDocumentsCount ?? 0}
        />
        <StatisticBadge
          className=""
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Calendar}
          label={t('Pages.Dashboard.percentageWorked')}
          value={
            dashboardData?.hoursProvided && dashboardData?.hoursPlanned
              ? `${Math.min(Math.round((dashboardData?.hoursProvided / dashboardData?.hoursPlanned) * 100), 100)}%`
              : '0%'
          }
        />
        <StatisticBadge
          className=""
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={CalendarYellow}
          label={t('Pages.Dashboard.hoursWorked')}
          value={`${dashboardData?.hoursProvided ? Math.round(dashboardData.hoursProvided) : '0'}h/${dashboardData?.hoursPlanned ? Math.round(dashboardData.hoursPlanned) : '0'}h`}
        />
      </div>

      <div className="grid min-h-[72px] grid-flow-col gap-4">
        {session && (
          <>
            {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS]) && (
              <NavItem
                path="/dashboard/all-therapists"
                className="bg-cyan-200"
                svgPath={UserSquareCyan}
                label={t('Pages.Dashboard.therapists')}
              />
            )}
            {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_CLIENTS]) && (
              <NavItem
                path="/dashboard/all-clients"
                className="bg-blue-50"
                svgPath={UserSquareBlue}
                label={t('Pages.Dashboard.clients')}
              />
            )}
            {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS]) && (
              <NavItem
                path="/dashboard/all-guardians"
                className="bg-rose-100"
                svgPath={UserSquareRose}
                label={t('Pages.Dashboard.guardians')}
              />
            )}
            {checkPermissions([PERMISSIONS_TYPES.VIEW_FULL_SCHEDULE]) && (
              <NavItem
                path="/dashboard/schedule"
                className="bg-yellow-100"
                svgPath={CalendarYellow}
                label={t('Pages.Dashboard.schedules')}
              />
            )}

            <NavItem
              path="/dashboard/all-shifts"
              className="bg-purple-100"
              svgPath={CalendarPurple}
              label={t('Pages.Dashboard.shifts')}
            />

            {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_SERVICES]) && (
              <NavItem
                path="/dashboard/all-services"
                className="bg-green-100"
                svgPath={HospitalGreen}
                label={t('Pages.Dashboard.services')}
              />
            )}
            {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS]) && (
              <NavItem
                path="/dashboard/all-documents"
                className="bg-red-100"
                svgPath={DocumentRose}
                label={t('Pages.Dashboard.documents')}
              />
            )}
          </>
        )}
      </div>

      {dashboardData && dashboardData.shifts && (
        <>
          <h1 className="py-4 text-xl font-semibold">{t('Pages.Dashboard.todaysSnapshot')}</h1>

          <div className="mt-1 grid grid-cols-3 gap-4 border-b border-slate-200 py-2">
            <div>
              <h3 className="mb-2 flex flex-row items-center pl-1 text-gray-400">
                <div className="mr-2 size-3 rounded-full bg-[#67943699] text-sm"></div> {t('Pages.Dashboard.scheduled')}{' '}
                {dashboardData.shifts.scheduled.length ? dashboardData.shifts.scheduled.length : 0}
              </h3>
              <ScrollArea className="h-[calc(100vh-426px)] overflow-auto">
                {dashboardData.shifts.scheduled.map((shift) => (
                  <LogEventItem
                    className="mb-2"
                    key={shift.id}
                    status={EventDashboardStatus.UPCOMING}
                    value={shift}
                    onPreview={() => previewShiftDialogRef.current?.openById(shift.id)}
                  />
                ))}
              </ScrollArea>
            </div>
            <div>
              <h3 className="mb-2 flex flex-row items-center pl-1 text-gray-400">
                <div className="mr-2 size-3 rounded-full bg-[#1B8A6B99] text-sm"></div> {t('Pages.Dashboard.completed')}{' '}
                {dashboardData.shifts.completed.length ? dashboardData.shifts.completed.length : 0}
              </h3>
              <ScrollArea className="h-[calc(100vh-426px)] overflow-auto">
                {dashboardData.shifts.completed.map((shift) => (
                  <LogEventItem
                    className="mb-2"
                    key={shift.id}
                    status={EventDashboardStatus.COMPLETED}
                    value={shift}
                    onPreview={() => previewShiftDialogRef.current?.openById(shift.id)}
                  />
                ))}
              </ScrollArea>
            </div>
            <div>
              <h3 className="mb-2 flex flex-row items-center pl-1 text-gray-400">
                <div className="mr-2 size-3 rounded-full bg-[#877C7C99] text-sm"></div> {t('Pages.Dashboard.noShow')}{' '}
                {dashboardData.shifts.noShow.length ? dashboardData.shifts.noShow.length : 0}
              </h3>
              <ScrollArea className="h-[calc(100vh-426px)] overflow-auto">
                {dashboardData.shifts.noShow.map((shift) => (
                  <LogEventItem
                    className="mb-2"
                    key={shift.id}
                    status={EventDashboardStatus.NO_SHOW}
                    value={shift}
                    onPreview={() => previewShiftDialogRef.current?.openById(shift.id)}
                  />
                ))}
              </ScrollArea>
            </div>
          </div>
          <PreviewShiftDialog onUpdated={refetch} ref={previewShiftDialogRef} />
        </>
      )}
    </main>
  );
}
