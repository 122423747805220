import { cn, getColorForServiceRole, transformDateToTime } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import { MySchedule } from './my-schedule.interface';
import { Chips } from '@/components/common';
import { EventInput } from '@fullcalendar/core/index.js';
import { SHIFT_STATUS_TYPES } from '@/common/enums';
import { CalendarClock, CarFront, Clock, Route } from 'lucide-react';

interface ShiftEvent extends EventInput {
  extendedProps: MySchedule;
}

export function EventRender(eventInfo: ShiftEvent) {
  if (!eventInfo) {
    return null;
  }

  const duration = dayjs(eventInfo.event.end).diff(eventInfo.event.start, 'minute');

  let trimClass;
  if (duration >= 60) {
    trimClass = 'line-clamp-2';
  } else {
    trimClass = 'truncate';
  }

  let colorStatus: string;
  switch (eventInfo.event.extendedProps.status) {
    case SHIFT_STATUS_TYPES.DRAFT:
      colorStatus = 'bg-[#427AA11A] text-[#36617E]';
      break;
    case SHIFT_STATUS_TYPES.CANCELED:
      colorStatus = 'bg-[#CECED61A] text-[#B7B7C1]';
      break;
    case SHIFT_STATUS_TYPES.COMPLETED:
      colorStatus = 'bg-[#1B8A6B1A] text-[#1B8A6B]';
      break;
    case SHIFT_STATUS_TYPES.NO_SHOW:
      colorStatus = 'bg-[#877C7C1A] text-[#877C7C]';
      break;
    case SHIFT_STATUS_TYPES.PENDING:
      colorStatus = 'bg-[#F4743B1A] text-[#DA6A38]';
      break;
    case SHIFT_STATUS_TYPES.SCHEDULED:
      colorStatus = 'bg-[#6794361A] text-[#567B2F]';
      break;
    default:
      colorStatus = 'bg-green-500';
      break;
  }

  if (eventInfo.event.extendedProps.startLocation) {
    colorStatus = 'bg-[#98A2B3] text-white';
  }

  return (
    <div
      className={cn(
        'flex h-full cursor-pointer flex-col justify-between rounded-sm border-2 border-blue-200 bg-white',
        eventInfo.status === SHIFT_STATUS_TYPES.NO_SHOW &&
          'bg-[repeating-linear-gradient(-45deg,_#F5F8FF,_#F5F8FF_15px,_#E6EEFF_5px,_#E6EEFF_30px)]'
      )}
    >
      <div className="p-1">
        {eventInfo.event.extendedProps.startLocation ? (
          <>
            <span className="mt-2 truncate px-2 font-bold uppercase text-gray-400">Travel Card</span>

            <div className="flex flex-row items-center justify-start space-x-1 truncate px-2">
              <span className="flex flex-row items-center text-xs text-gray-800">
                <Route className="mr-1 size-4 text-gray-600" />{eventInfo.event.extendedProps.estimatedMileage.toFixed(2)} Miles
              </span>
              <span className="flex flex-row items-center text-xs text-gray-800">
                <Clock className="mr-1 size-4 text-gray-600" />
                {(eventInfo.event.extendedProps.estimatedTime * 60).toFixed(2)} Min
              </span>
            </div>
          </>
        ) : (
          <>
            <p className={cn('px-2 text-sm font-medium text-blue-950', trimClass)}>{eventInfo.event.title}</p>
            <Chips
              size="sm"
              color={getColorForServiceRole(
                eventInfo.event.extendedProps.serviceRole.title,
                eventInfo.event.extendedProps.serviceRole.speciality.code
              )}
              title={eventInfo.event.extendedProps.serviceRole.title}
            />
          </>
        )}
      </div>

      <div className="grid w-full min-w-0">
        <p
          className={`flex flex-row items-center justify-between truncate whitespace-nowrap rounded-b px-2 text-xs font-light ${colorStatus}`}
        >
          {`${transformDateToTime(eventInfo.event.extendedProps.startAt)} - ${transformDateToTime(eventInfo.event.extendedProps.endAt)}`}

          <span>
            {eventInfo.event.extendedProps.startLocation ? (
              <CarFront className="ml-1 size-4" />
            ) : (
              <CalendarClock className="ml-1 size-4" />
            )}
          </span>
        </p>
      </div>
    </div>
  );
}
