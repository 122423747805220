import { ColumnDef } from '@tanstack/react-table';
import { compileFullName, minutesToTime } from '@/lib/utils';
import { Chips } from '@/components/common';
import { TimesheetPayroll } from '../interfaces/timesheet-payroll.interface';
import { USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

type Params = {
  t: any;
  onOpenTherapistProfile: (id: string) => void;
  checkPermissions: (permissions: PERMISSIONS_TYPES[]) => boolean;
};

export const getTimesheetDetailsColumns = ({
  t,
  onOpenTherapistProfile,
  checkPermissions,
}: Params): ColumnDef<TimesheetPayroll>[] => {
  const columns: ColumnDef<TimesheetPayroll>[] = [
    {
      id: 'Therapist',
      accessorKey: 'type',
      header: () => <span>{t('Tables.therapist')}</span>,
      cell: ({ row }) => {
        if (row.original.therapist) {
          return (
            <Chips
              onClickAction={
                checkPermissions([PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE])
                  ? (e) => {
                      onOpenTherapistProfile(row.original.therapist.id);
                      e.stopPropagation();
                    }
                  : undefined
              }
              color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
              title={compileFullName(row.original.therapist)}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Hours Worked',
      accessorKey: 'totalDuration',
      enablePinning: false,
      enableSorting: false,
      accessorFn: (row) => (row.totalDuration ? minutesToTime(row.totalDuration) : '---'),
      header: () => <span>{t('Tables.hoursWorked')}</span>,
      size: 150,
    },
    {
      id: 'ADP ID',
      enablePinning: false,
      enableSorting: false,
      header: () => <span>ADP ID</span>,
      accessorFn: (row) => (row.therapist ? (row.therapist.adpId ? t('Common.yes') : t('Common.no')) : '---'),
      size: 60,
    },
    {
      id: 'Drive time',
      enablePinning: false,
      header: () => <span>{t('Tables.driveTime')}</span>,
      cell: ({ row }) => {
        return <span>{row.original.totalDriveTime ? minutesToTime(row.original.totalDriveTime) : '---'}</span>;
      },
      size: 140,
    },
    {
      id: 'Amount of miles',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfMiles')}</span>,
      cell: ({ row }) => {
        return <span>{row.original.totalDriveMileage ? row.original.totalDriveMileage.toFixed(2) : '---'}</span>;
      },
      size: 140,
    },
    {
      id: 'Amount of rest breaks',
      enablePinning: false,
      header: () => <span>{t('Tables.amountOfRestBreaks')}</span>,
      cell: ({ row }) => {
        return <span>{row.original.breaksCount ? row.original.breaksCount : '0'}</span>;
      },
      size: 140,
    },
  ];
  if (checkPermissions([PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC])) {
    // Only for super admin
    columns.push({
      id: 'Total Pay',
      accessorKey: 'totalPay',
      enablePinning: false,
      enableSorting: false,
      accessorFn: (row) => (row.totalPay ? `$${row.totalPay.toFixed(2)}` : '---'),
      header: () => <span>{t('Tables.totalPay')}</span>,
      size: 140,
    });
  }

  return columns;
};
