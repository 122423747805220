'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { createRef, useEffect, useState } from 'react';
import { Pagination, CommonTable, ColumnAdvance, TableColumnSettings } from '@/components/common';
import { useTranslations } from 'next-intl';
import { getDetailedCalculationColumns } from './my-detailed-calculation-columns';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { TimesheetPayrollTherapist } from '@/views/timesheet-details/interfaces/timesheet-payroll-therapist.interface';
import { useTimesheetPayrollQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollQuery';
import { Timesheet } from '@/views/timesheets/interfaces/timesheet.interface';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import { myDetailedCalculationSortingHelper } from './my-detailed-calculation-sorting-helper';
import { PAYROLL_ENTRY_TYPE } from '@/views/timesheet-details/enums/payroll-entry-type';
import {
  PreviewRestBrakeDialog,
  PreviewRestBrakeDialogRef,
} from '@/views/timesheets/preview/preview-rest-brake.dialog';
import { PreviewTravelCardDialog, PreviewTravelCardDialogRef } from '@/views/schedule/preview-travel-card.dialog';
import { PreviewShiftTimesheetDialog, PreviewShiftTimesheetDialogRef } from '@/views/timesheets/preview/preview-shift-timesheet.dialog';

const PER_PAGE = 100;

export function MyDetailedCalculationTable({
  timesheet,
}: {
  timesheet: Timesheet & {
    period: string;
  };
}) {
  const t = useTranslations();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'Date',
      desc: true,
    },
  ]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const previewShiftTimesheetDialogRef = createRef<PreviewShiftTimesheetDialogRef>();
  const previewRestBrakeDialogRef = createRef<PreviewRestBrakeDialogRef>();
  const previewTravelCardDialogRef = createRef<PreviewTravelCardDialogRef>();
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const [data, setData] = useState<TimesheetPayrollTherapist[]>([]);

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.DETAILED_CALCULATION);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableData, isLoading } = useTimesheetPayrollQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    id: timesheet.id,
    ...myDetailedCalculationSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<TimesheetPayrollTherapist>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  // const handlePreviewService = (serviceId: string) => {
  //   previewServiceDialogRef.current?.openById(serviceId);
  // };

  const table = useReactTable({
    columns: getDetailedCalculationColumns({
      t,
      // onPreviewService: handlePreviewService
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<TimesheetPayrollTherapist>[]) => {
    setTableConfig({
      name: TABLE_TYPES.DETAILED_CALCULATION,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<TimesheetPayrollTherapist>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-end py-2">
        <div className="flex flex-row items-center space-x-2">
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-464px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => {
              switch (rowOriginal.entryType) {
                case PAYROLL_ENTRY_TYPE.Shift:
                  previewShiftTimesheetDialogRef.current?.openById(rowOriginal.id, timesheet.period);
                  break;
                case PAYROLL_ENTRY_TYPE.Break:
                  previewRestBrakeDialogRef.current?.openById(rowOriginal.id, true, timesheet.period);
                  break;
                case PAYROLL_ENTRY_TYPE.Trip:
                  previewTravelCardDialogRef.current?.openById(rowOriginal.id, true, timesheet.period);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}
      <PreviewServiceDialog ref={previewServiceDialogRef} />
      <PreviewShiftTimesheetDialog showPayMetrics ref={previewShiftTimesheetDialogRef} />
      <PreviewRestBrakeDialog ref={previewRestBrakeDialogRef} />
      <PreviewTravelCardDialog ref={previewTravelCardDialogRef} />
    </>
  );
}
