import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { transformViewDateToApiDate } from '@/lib/utils';
import { Client } from '@/views/all-clients/interfaces/clients.interface';
import { CreateClientAndGuardianValidationSchema } from '@/views/all-clients/manage/create-client-and-guardian-validation-schema';
import { DefaultError, UseMutationOptions, useMutation } from '@tanstack/react-query';
import * as _ from 'lodash';

interface Request extends CreateClientAndGuardianValidationSchema {
  guardianId?: string;
  fileId: string;
}

export type Options = Omit<UseMutationOptions<Client, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useCreateOCRClientMutation = (options?: Options) => {
  const _mutation = async (value: Request): Promise<Client> => {
    const _client = {
      firstName: value.firstName,
      lastName: value.lastName,
      uci: value.uci,
      dob: transformViewDateToApiDate(value.dob),
      regionalCenter: value.regionalCenter,
      addresses: value.addresses,
      caseManager: value.caseManager,
    };

    const client = _.omitBy(_client, (v) => _.isNil(v) || v === '');

    const _guardian = {
      firstName: value.firstNameGuardian,
      lastName: value.lastNameGuardian,
      email: value.email,
      languages: value.languages.map((tag) => tag.label),
      secondaryNumber: value.secondaryNumber ? value.secondaryNumber.replace('+', '') : '',
      primaryRelationship: value.primaryRelationship,
      secondaryRelationship: value.secondaryRelationship,
      primaryNumber: value.primaryNumber.replace('+', ''),
    };

    const guardian = _.omitBy(_guardian, (v) => _.isNil(v) || v === '');

    const parsedValue = {
      client,
      guardian,
      guardianId: value.guardianId,
      ocrFileId: value.fileId,
    };

    const body = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`ocr/client`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['ocr', 'client', 'clients'],
    mutationFn: _mutation,
    ...options,
  });
};
