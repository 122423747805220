import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { geocode, OutputFormat } from '@/lib/geocode';
import { ParsedClientFromPDF } from '@/views/all-clients/interfaces/clients.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, ParsedClientFromPDF>, 'queryKey' | 'queryFn'>;

export const useLongPollingFileQuery = (fileId?: string, options?: Options) => {
  return useQuery({
    queryKey: ['long-polling', fileId],
    queryFn: async ({ signal }) => {
      const { data } = await axiosInterceptorInstance.get(`long-polling/${fileId}`, {
        // timeout: 180000,
        signal,
      });

      // if (data.address) {
      //   try {
      //     const location = await geocode(data.address, {
      //       key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      //       outputFormat: OutputFormat.JSON,
      //     });
      //     data.address = location.results[0] && location.results[0].formatted_address ? location.results[0].formatted_address : data.address;
      //     if (location.results[0] && location.results[0].geometry) {
      //       data.lng = location.results[0].geometry.location.lng;
      //       data.lat = location.results[0].geometry.location.lat;
      //     }
      //   } catch (error) {
      //     console.log(`%c DEBUG LOG: Google Maps Geocoding API`, 'color: yellow');
      //     console.log(error);
      //   }
      // }
      return data;
    },
    refetchOnMount: false,
    ...options,
  });
};
