import { ColumnDef, Row } from '@tanstack/react-table';
import { cn, transformApiDateToViewDate } from '@/lib/utils';
import { Trash2 } from 'lucide-react';
import { Client, Guardian } from '../interfaces/clients.interface';
import { Chips, CollapsibleColumn } from '@/components/common';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { USER_ROLES_COLORS } from '@/common/constants';
import { Colors } from '@/common/enums';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Client>) => void;
  onPreviewGuardian: (guardian: Guardian) => void;
  isCanRemove?: boolean;
};

export const getAllClientsColumns = ({ t, onConfirmRemove, onPreviewGuardian, isCanRemove = true }: Params): ColumnDef<Client>[] => {
  const columns: ColumnDef<Client>[] = [
    {
      id: 'Full Name',
      accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
      header: () => <span>{t('Tables.fullName')}</span>,
      size: 250, //starting column size
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'UCI Number',
      accessorKey: 'uci',
      header: () => <span>{t('Tables.uciNumber')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Address',
      header: () => <span>{t('Tables.address')}</span>,
      cell: ({ row }) => {
        if (row.original.addresses) {
          const visible = row.original.addresses.slice(0, 1);
          const hidden = row.original.addresses.slice(1);

          const parseFn = (item: any) => ({
            id: item.id,
            title: item.address,
            color: Colors.none,
          });

          return <CollapsibleColumn withoutChips visible={visible.map(parseFn)} hidden={hidden.map(parseFn)} />;
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Case Manager',
      accessorKey: 'caseManager',
      header: () => <span>{t('Tables.caseManager')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Regional Center',
      accessorKey: 'regionalCenter',
      header: () => <span>{t('Tables.regionalCenter')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Guardian',
      accessorKey: 'type',
      header: () => <span>{t('Tables.guardian')}</span>,
      cell: ({ row }) => {
        if (row.original.guardians) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {row.original.guardians.map((guardian) => (
                <Chips
                  onClickAction={(e) => {
                    onPreviewGuardian(guardian);
                    e.stopPropagation();
                  }}
                  key={guardian.id}
                  color={USER_ROLES_COLORS[ROLE_TYPES.Guardian]}
                  title={`${guardian.firstName} ${guardian.lastName}`}
                />
              ))}
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Date of Birth',
      accessorFn: (row) => transformApiDateToViewDate(row.dob),
      header: () => <span>{t('Tables.dateOfBirth')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 120,
    },
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <div
          className="flex min-h-8 min-w-8 flex-row items-center justify-center"
          onClick={(e) => {
            onConfirmRemove(row);
            e.stopPropagation();
          }}
        >
          <Trash2 className="w-[18px] cursor-pointer text-gray-400" />
        </div>
      ),
      size: 50,
    });
  }

  return columns;
};
