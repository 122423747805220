'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import { Button, Dialog, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { useTranslations } from 'next-intl';
import {
  clearFilter,
  parseFilterToArrayOfTagsData,
  CommonTable,
  SimpleSearch,
  TableFilter,
  ConfirmDialog,
  Pagination,
  toast,
  ToastAction,
  ToastTypeEnums,
  UploadFileDialog,
  ToggleFilter,
  ColumnAdvance,
  TableColumnSettings,
  ConfirmDialogRef,
} from '@/components/common';
import { AllClientsFilterForm, FilterAllClientsParams } from '../header-filter/all-clients-filter-form';
import { Client, Guardian } from '../interfaces/clients.interface';
import { PreviewClientDialog, PreviewClientDialogRef } from '../preview/preview-client.dialog';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { useRemoveClientMutation } from '@/hooks/fetchers/mutations/clients/useRemoveClientMutation';
import { useRemoveUndoClientMutation } from '@/hooks/fetchers/mutations/clients/useRemoveUndoClientMutation';
import { getAllClientsColumns } from './all-clients-columns';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { ManageClientChildManuallyForm } from '../manage/manage-client-child-manually-form';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { UploadFileDialogRef } from '@/components/common';
import {
  CreateClientAndGuardianDialog,
  CreateClientAndGuardianDialogRef,
} from '../manage/create-client-and-guardian.dialog';
import { ClientProfileTabs } from '../enums/client-profile-tabs';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '@/views/all-guardians/preview/preview-guardian.dialog';
import { useTableFilterDefaultQuery } from '@/hooks/fetchers/queries/useTableFilterDefaultQuery';
import { useTableFilterDefaultMutation } from '@/hooks/fetchers/mutations/useTableFilterDefaultMutation';
import { allClientsSortingHelper } from './all-clients-sorting-helper';

const PER_PAGE = 100;

export function AllClientsTable() {
  const t = useTranslations();
  const [data, setData] = useState<Client[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [actionPopoverOpen, setActionPopoverOpen] = useState(false);
  const [filter, setFilter] = useState<FilterAllClientsParams>({});
  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const uploadFileDialogRef = createRef<UploadFileDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const createClientAndGuardianDialogRef = createRef<CreateClientAndGuardianDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.ALL_CLIENTS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableDefFilters } = useTableFilterDefaultQuery(TABLE_TYPES.ALL_CLIENTS);
  const { mutate: setTableDefFilters } = useTableFilterDefaultMutation();
  const {
    data: tableData,
    refetch: refetchClients,
    isLoading,
  } = useClientsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    showDeleted,
    ...filter,
    ...allClientsSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Client>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
      setPagination((prev) => ({ ...prev, page: 0 }));
  }, [JSON.stringify(filter), showDeleted]);

  const { mutate: removeClient } = useRemoveClientMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoClient(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchClients();
    },
  });

  const { mutate: removeUndoClient } = useRemoveUndoClientMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchClients();
    },
  });

  const handleConfirmRemove = (row: Row<Client>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteClient', { name: `${row.original.firstName} ${row.original.lastName}` }),
      value: row.original.id,
    });
  };

  const handlePreviewGuardian = (guardian: Guardian) => {
    previewGuardianDialogRef.current?.openById(guardian.id);
  };

  const table = useReactTable({
    columns: getAllClientsColumns({
      t,
      onPreviewGuardian: handlePreviewGuardian,
      onConfirmRemove: handleConfirmRemove,
      isCanRemove: checkPermissions([PERMISSIONS_TYPES.DELETE_CLIENT]),
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Client>[]) => {
    setTableConfig({
      name: TABLE_TYPES.ALL_CLIENTS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  useEffect(() => {
    if (tableDefFilters && tableDefFilters.filters) {
      setFilter(tableDefFilters.filters);
    }
  }, [JSON.stringify(tableDefFilters)]);

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const handleSelectFile = (file: File) => {
    createClientAndGuardianDialogRef.current?.open(file);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Client>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          onSaveAsDefaultFilter={(filters) => {
            setTableDefFilters({
              name: TABLE_TYPES.ALL_CLIENTS,
              filters,
            });
          }}
          formComponent={AllClientsFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {checkPermissions([PERMISSIONS_TYPES.CREATE_CLIENT]) && (
            <Popover open={actionPopoverOpen} onOpenChange={setActionPopoverOpen}>
              <PopoverTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 size-4" /> {t('Buttons.addClient')}
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className="w-40">
                <div className="flex flex-col space-y-4">
                  <Button
                    onClick={() =>
                      uploadFileDialogRef.current?.open({
                        accept: ['.pdf'],
                        maxSizeMb: 10,
                        allowTypes: ['pdf', 'PDF'],
                        maxSizeText: '10MB',
                        typeText: 'PDF',
                      })
                    }
                    size="lg"
                    variant="outline"
                    className="w-full"
                  >
                    {t('Buttons.automatically')}
                  </Button>
                  <Dialog open={createClientDialogOpen} onOpenChange={setCreateClientDialogOpen}>
                    <DialogTrigger asChild>
                      <Button size="lg" variant="outline" className="w-full">
                        {t('Buttons.manually')}
                      </Button>
                    </DialogTrigger>
                    <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
                      <DialogHeader>
                        <DialogTitle>{t('Pages.AllClients.addNewClient')}</DialogTitle>
                        <DialogDescription></DialogDescription>
                      </DialogHeader>
                      <ManageClientChildManuallyForm
                        onCancel={() => setCreateClientDialogOpen(false)}
                        onRefresh={() => {
                          setCreateClientDialogOpen(false);
                          refetchClients();
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              </PopoverContent>
            </Popover>
          )}
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-60 grid-cols-2 gap-1">
        {checkPermissions([PERMISSIONS_TYPES.VIEW_FILTER_ARCHIVED]) && (
          <>
            <ToggleFilter label={t('Buttons.active')} onChange={() => setShowDeleted(false)} isActive={!showDeleted} />
            <ToggleFilter label={t('Buttons.archived')} onChange={() => setShowDeleted(true)} isActive={showDeleted} />
          </>
        )}
      </div>

      <div className="relative h-[calc(100vh-296px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewClientDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeClient(id)}
        title={t('Common.deleteClient')}
        type="delete"
      />

      <PreviewClientDialog ref={previewClientDialogRef} />
      <PreviewGuardianDialog onUpdated={refetchClients} ref={previewGuardianDialogRef} />
      <UploadFileDialog onSelect={(file) => handleSelectFile(file)} ref={uploadFileDialogRef} />
      <CreateClientAndGuardianDialog
        onCreated={(client) => {
          setTimeout(() => {
            previewClientDialogRef.current?.open(client, ClientProfileTabs.SERVICE);
          }, 1000);
        }}
        ref={createClientAndGuardianDialogRef}
      />
    </>
  );
}
