import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Folder from './../../../../public/icons/folder-blue.svg';
import Calendar from './../../../../public/icons/calendar-blue.svg';
import Wallet from './../../../../public/icons/wallet-money.svg';
import Coffee from './../../../../public/icons/coffee.svg';
import Routing from './../../../../public/icons/routing.svg';
import Car from './../../../../public/icons/car.svg';
import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { StatisticBadge } from '@/components/common';

import { minutesToTime } from '@/lib/utils';
import { MyDetailedCalculationTable } from '../table/my-detailed-calculation-table';
import { Timesheet } from '@/views/timesheets/interfaces/timesheet.interface';
import dayjs from 'dayjs';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

export type MyDetailedCalculationDialogRef = {
  open: (timesheet: Timesheet) => void;
};

const MyDetailedCalculationDialog = forwardRef<MyDetailedCalculationDialogRef>(({}, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const [timesheetData, setTimesheetData] = useState<Timesheet | null>(null);

  useImperativeHandle(ref, () => ({
    open: (value) => {
      setIsOpen(true);
      setTimesheetData(value);
    },
  }));

  const period = timesheetData ? `${dayjs(timesheetData.periodFrom).format('MMM')} ${dayjs(timesheetData.periodFrom).format('DD')} - ${dayjs(timesheetData.periodTo).format('MMM')} ${dayjs(timesheetData.periodTo).format('DD')}` : '';

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle className="flex flex-row items-center justify-start">
              {t('Pages.MyTimesheets.previewTimesheet')}
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="h-[calc(100vh-180px)]">
            <div className="grid grid-cols-3 gap-4 p-4">
            <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Calendar}
                label={t('Pages.Timesheets.period')}
                value={period}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Folder}
                label={t('Pages.Timesheets.hoursProvided')}
                value={timesheetData ? minutesToTime(timesheetData.report.totalDuration) : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Wallet}
                label={t('Pages.Timesheets.totalPay')}
                value={timesheetData ? `$${timesheetData.report.totalPay.toFixed(2)}` : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Car}
                label={t('Pages.Timesheets.totalDriveTime')}
                value={timesheetData?.report.totalDriveTime ? minutesToTime(timesheetData.report.totalDriveTime) : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Routing}
                label={t('Pages.Timesheets.totalMiles')}
                value={timesheetData?.report.totalDriveTime ? timesheetData.report.totalDriveMileage.toFixed(2) : `---`}
              />
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Coffee}
                label={t('Pages.Timesheets.totalAmountOfRestBreaks')}
                value={timesheetData?.report.totalDriveTime ? timesheetData?.report.breaksCount : `---`}
              />
            </div>

            {timesheetData && <MyDetailedCalculationTable timesheet={{ ...timesheetData, period }} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

MyDetailedCalculationDialog.displayName = 'MyDetailedCalculationDialog';

export { MyDetailedCalculationDialog };
