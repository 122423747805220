import { Chips } from '@/components/common';
import { cn, compileFullName, transformDateToTime } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import { EventDashboardStatus } from '../event-dashboard-status';
import { ShiftData } from '../dashboard.interface';
import { Colors } from '@/common/enums';

interface Props {
  value: ShiftData;
  className?: string;
  status: EventDashboardStatus;
  onPreview: (id: string) => void;
}

export function LogEventItem({ value, status, onPreview, className }: Props) {
  let colorStatus: string;
  switch (status) {
    case EventDashboardStatus.SCHEDULED:
      colorStatus = 'bg-[#6794361A] text-[#567B2F]';
      break;
    case EventDashboardStatus.COMPLETED:
      colorStatus = 'bg-[#1B8A6B1A] text-[#1B8A6B]';
      break;
    case EventDashboardStatus.NO_SHOW:
      colorStatus = 'bg-[#877C7C1A] text-[#877C7C]';
      break;
    default:
      colorStatus = 'bg-[#6794361A] text-[#567B2F]';
      break;
  }

  if (value.startAt) {
    value.startTime = transformDateToTime(value.startAt);
  }
  if (value.endAt) {
    value.endTime = transformDateToTime(value.endAt);
  }

  const footerClass = `flex flex-row items-center h-9 whitespace-nowrap rounded-b ${colorStatus} px-2 text-sm font-medium`;
  return (
    <div
      onClick={() => onPreview(value.id)}
      className={cn(
        'relative flex h-28 w-full cursor-pointer flex-col justify-between rounded-sm border border-blue-100 bg-white',
        status === EventDashboardStatus.NO_SHOW &&
        'bg-[repeating-linear-gradient(-45deg,_#fdfbfb,_#fdfbfb_20px,_#FFFFFF_10px,_#FFFFFF_40px)]',
        className
      )}
    >
      <div className="p-2">
        <p className={cn('mb-2 line-clamp-2 pr-24 text-sm font-semibold text-blue-950')}>
          {compileFullName(value.therapist)}
        </p>
        <Chips color={Colors.green} title={value.service ? value.service.name : '---'} />
      </div>
      <span className="absolute right-2 top-2 font-medium text-gray-600">{`${value.startTime}-${value.endTime}`}</span>
      <p className={footerClass}>{compileFullName(value.client)}</p>
    </div>
  );
}
