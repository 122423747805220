'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useState } from 'react';
import {
  CommonTable,
  SimpleSearch,
  ColumnAdvance,
  TableColumnSettings,
  ConfirmDialog,
  Pagination,
  toast,
  ToastTypeEnums,
} from '@/components/common';
import { Button } from '@/components/ui';
import { useTranslations } from 'next-intl';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { DetailedCalculationDialog, DetailedCalculationDialogRef } from '../preview/detailed-calculation.dialog';
import { useApproveTimesheetMutation } from '@/hooks/fetchers/mutations/timesheets/useApproveTimesheetMutation';
import { TimesheetPayroll } from '../interfaces/timesheet-payroll.interface';
import { useTimesheetPayrollsQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollsQuery';
import { getTimesheetDetailsColumns } from './timesheet-details-columns';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TABLE_TYPES } from '@/common/enums';
import { useIsAllowed } from '@/lib/RBAC';
import { timesheetDetailsSortingHelper } from './timesheet-details-sorting-helper';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

const PER_PAGE = 100;

export function TimesheetDetailsTable({
  timesheetId,
  period,
  status,
}: {
  timesheetId: string;
  period: string;
  status?: string;
}) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const [data, setData] = useState<TimesheetPayroll[]>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewTimesheetDialogRef = createRef<DetailedCalculationDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.TIMESHEET_DETAILS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const {
    data: tableData,
    refetch: refetchTimesheetPayrolls,
    isLoading,
  } = useTimesheetPayrollsQuery({
    id: timesheetId,
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    ...timesheetDetailsSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<TimesheetPayroll>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const table = useReactTable({
    columns: getTimesheetDetailsColumns({
      t,
      onOpenTherapistProfile: (id) => previewTherapistDialogRef.current?.openById(id),
      checkPermissions,
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const { mutate: approve, isPending } = useApproveTimesheetMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        // description: t('Toasts.setOnHoldService'),
      });
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<TimesheetPayroll>[]) => {
    setTableConfig({
      name: TABLE_TYPES.TIMESHEET_DETAILS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<TimesheetPayroll>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-end py-2">
        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {checkPermissions([PERMISSIONS_TYPES.APPROVE_PAYROLL]) && (
            <ConfirmDialog
              onConfirm={() => approve({ id: timesheetId })}
              title={t('Common.approvePayroll')}
              type="warning"
              description={t('Common.confirmApprovePayroll')}
            >
              <Button type="button" size="sm" disabled={isPending || status === 'approved'}>
                {t('Buttons.approve')}
              </Button>
            </ConfirmDialog>
          )}
          <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />
        </div>
      </header>

      <div className="relative h-[calc(100vh-440px)] max-w-full overflow-auto">
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewTimesheetDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <DetailedCalculationDialog period={period} ref={previewTimesheetDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchTimesheetPayrolls} ref={previewTherapistDialogRef} />
    </>
  );
}
