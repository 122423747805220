import { LabelValueItem, StatisticBadge } from '@/components/common';
import { Dialog } from '@/components/ui';
import Calendar from './../../../public/icons/calendar-blue.svg';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Routing from './../../../public/icons/routing.svg';
import Car from './../../../public/icons/car.svg';
import Wallet from './../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TripEvent } from '../my-schedule/my-schedule.interface';
import { useTimesheetPayrollEntryQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetPayrollEntityQuery';

export type PreviewTravelCardDialogRef = {
  open: (value: TripEvent, isShowAnalytics?: boolean) => void;
  openById: (id: string, isShowAnalytics?: boolean, period?: string) => void;
};

const PreviewTravelCardDialog = forwardRef<PreviewTravelCardDialogRef>(({}, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [entryId, setEntryId] = useState<string>();
  const [period, setPeriod] = useState<string>();
  const [isShowAnalytics, setIsShowAnalytics] = useState<boolean>(false);
  const t = useTranslations();
  const [travelDetails, setTravelDetails] = useState<TripEvent>();
  const { data: entry } = useTimesheetPayrollEntryQuery({ id: entryId });

  useImperativeHandle(ref, () => ({
    open: (value, showAnalytics = false) => {
      setIsShowAnalytics(showAnalytics);
      setTravelDetails(value);
      setIsOpen(true);
    },
    openById: (id, showAnalytics = false, period) => {
      setIsShowAnalytics(showAnalytics);
      setPeriod(period);
      setEntryId(id);
      setIsOpen(true);
    },
  }));

  useEffect(() => {
    if (entry) {
      setTravelDetails(entry.therapistTrip);
    }
  }, [entry]);

  useEffect(() => {
    if (!isOpen && entryId) {
      setEntryId(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full min-w-[700px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.Schedule.tripDetails')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        {travelDetails && (
          <>
            {isShowAnalytics && (
              <div className="mb-6 grid grid-cols-2 gap-4">
                <StatisticBadge
                  size="lg"
                  iconHeight={54}
                  iconWidth={54}
                  classNameIcon="p-3 ml-3"
                  svgPath={Calendar}
                  label={t('Pages.Schedule.period')}
                  value={period ?? ''}
                />
                <StatisticBadge
                  size="lg"
                  iconHeight={54}
                  iconWidth={54}
                  classNameIcon="p-3 ml-3"
                  svgPath={Car}
                  label={t('Pages.Schedule.driveTime')}
                  value={`${travelDetails.estimatedTime.toFixed(2)}h`}
                />
                <StatisticBadge
                  size="lg"
                  iconHeight={54}
                  iconWidth={54}
                  classNameIcon="p-3 ml-3"
                  svgPath={Wallet}
                  label={t('Pages.Schedule.totalPay')}
                  value={`$${travelDetails.totalPay.toFixed(2)}`}
                />
                <StatisticBadge
                  size="lg"
                  iconHeight={54}
                  iconWidth={54}
                  classNameIcon="p-3 ml-3"
                  svgPath={Routing}
                  label={t('Pages.Schedule.miles')}
                  value={`${travelDetails.estimatedMileage.toFixed(2)} miles`}
                />
              </div>
            )}

            <div className="grid grid-cols-form-cols-2 gap-8">
              <LabelValueItem
                label={t('Pages.Schedule.driveTime')}
                value={`${travelDetails.estimatedTime.toFixed(2)}h`}
              />
              <LabelValueItem
                label={t('Pages.Schedule.payRate')}
                value={`${travelDetails.payRateTime.toFixed(2)}/hour`}
              />
              <LabelValueItem
                label={t('Pages.Schedule.miles')}
                value={`${travelDetails.estimatedMileage.toFixed(2)} miles`}
              />
              <LabelValueItem
                label={t('Pages.Schedule.mileageRate')}
                value={`$${travelDetails.payRateMileage.toFixed(2)}/mile`}
              />
              <LabelValueItem
                label={t('Pages.Schedule.mileageReimbursement')}
                value={`$${(travelDetails.estimatedMileage * travelDetails.payRateMileage).toFixed(2)}`}
              />
              <LabelValueItem label={t('Pages.Schedule.totalPay')} value={`$${travelDetails.totalPay.toFixed(2)}`} />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});

PreviewTravelCardDialog.displayName = 'PreviewTravelCardDialog';

export { PreviewTravelCardDialog };
