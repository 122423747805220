import { ColumnDef } from '@tanstack/react-table';
import dayjs from '@/lib/dayjsConfig';
import { TimesheetPayrollTherapist } from '../interfaces/timesheet-payroll-therapist.interface';
import { capitalizeFirstLetter, getColorForServiceRole, minutesToTime } from '@/lib/utils';
import { Chips } from '@/components/common';
import { PAYROLL_ENTRY_TYPE } from '../enums/payroll-entry-type';

type Params = {
  t: any;
  viewPayMetrics: boolean;
  // onPreviewService: (serviceId: string) => void;
};

export const getDetailedCalculationColumns = ({
  t,
  // onPreviewService,
  viewPayMetrics,
}: Params): ColumnDef<TimesheetPayrollTherapist>[] => {
  const columns: ColumnDef<TimesheetPayrollTherapist>[] = [
    // {
    //   id: 'Service',
    //   header: () => <span>{t('Tables.service')}</span>,
    //   cell: ({ row }) => {
    //     if (row.original.shift && row.original.shift.service) {
    //       return (
    //         <div className="flex flex-row flex-wrap gap-1 py-2">
    //           <Chips
    //             onClickAction={(e) => {
    //               onPreviewService(row.original.shift.service.id);
    //               e.stopPropagation();
    //             }}
    //             warningBadge={row.original.shift.service.deletedAt && t('Common.archived')}
    //             color={getColorForServiceRole(row.original.shift.service.title, row.original.shift.service.serviceRole?.speciality.code)}
    //             title={row.original.shift.service.title}
    //           />
    //         </div>
    //       );
    //     }
    //   },
    //   size: 250,
    //   enableSorting: false,
    //   enablePinning: false,
    // },
    {
      id: 'Type',
      enablePinning: false,
      enableSorting: false,
      accessorFn: (row) => (row.entryType ? capitalizeFirstLetter(row.entryType) : '---'),
      header: () => <span>{t('Tables.type')}</span>,
      size: 140,
    },
    // {
    //   id: 'Type of Visit',
    //   enablePinning: false,
    //   enableSorting: false,
    //   accessorFn: (row) => (row.workType ? capitalizeFirstLetter(row.workType) : '---'),
    //   header: () => <span>{t('Tables.typeOfVisit')}</span>,
    //   size: 140,
    // },
    {
      id: 'Date',
      enablePinning: false,
      enableSorting: true,
      header: () => <span>{t('Tables.date')}</span>,
      accessorFn: (row) => (row.date ? dayjs(row.date).format('MM.DD.YYYY') : '---'),
      size: 140,
    },
    {
      id: 'Hours',
      accessorKey: 'duration',
      enablePinning: false,
      enableSorting: false,
      header: () => <span>{t('Tables.hours')}</span>,
      accessorFn: (row) => (row.duration ? minutesToTime(row.duration) : '---'),
      size: 140,
    },
  ];

  if (viewPayMetrics) {
    // Only for super admin
    columns.push(
      // {
      //   id: 'Pay Rate',
      //   accessorKey: 'payRate',
      //   enablePinning: false,
      //   enableSorting: false,
      //   header: () => <span>{t('Tables.payRate')}</span>,
      //   cell: ({ row }) => {
      //     switch (row.original.entryType) {
      //       case PAYROLL_ENTRY_TYPE.Shift:
      //         return row.original.payRate ? `$${row.original.payRate.toFixed(2)}` : '---';
      //       case PAYROLL_ENTRY_TYPE.Trip:
      //         return row.original.therapistTrip ? `$${row.original.therapistTrip.payRateTime.toFixed(2)}` : '---';
      //       case PAYROLL_ENTRY_TYPE.Break:
      //         return row.original.payRate ? `$${row.original.payRate.toFixed(2)}` : '---';
      //       default:
      //         break;
      //     }
      //   },
      //   size: 140,
      // },
      {
        id: 'Total Pay',
        accessorKey: 'totalPay',
        enablePinning: false,
        enableSorting: false,
        header: () => <span>{t('Tables.totalPay')}</span>,
        accessorFn: (row) => (row.totalPay ? `$${row.totalPay.toFixed(2)}` : '---'),
        size: 140,
      }
    );
  }

  return columns;
};
